import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanmortoni/Code/Personal/qvsr/node_modules/gatsby-theme-docz/src/base/Layout.js";
import imageUrl from '../assets/logo.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <img src={imageUrl} alt="Image alt" style={{
      borderRadius: '8px'
    }} />
    <div style={{
      margin: '0 0 80px',
      color: '#6a6a6a',
      display: 'flex',
      justifyContent: 'center'
    }}>
  <div style={{
        maxWidth: '650px',
        textAlign: 'center'
      }}>
    QVSR gives you the best quadratic and linear voting experience as a survey tool, with Qualtrics integration and
    Analytics support.
  </div>
    </div>
    <div style={{
      textAlign: 'justify',
      marginBottom: '30px'
    }}>
  QVSR stands for “Quadratic Voting for Survey Research.” It is a new survey tool that builds on both old (using a
  budget constraint) and new (quadratic voting) ideas in survey research. For additional information on QVSR, please
  visit the FAQ. The QVSR web application is developed by a group of researchers and developers dedicated to making QVSR
  available for free to researchers and non-profit actors. This project is open source and we encourage further
  development and experimentation with this web app and it’s concepts.
    </div>
    <p>{`The purpose of this documentation is to guide you through the core functionalities of the web application and assist in forking the repos itory and creating your own customization. `}<a parentName="p" {...{
        "href": "https://github.com/mortoni/qvsr"
      }}>{`QVSR repository`}</a>{`.`}</p>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <h4 {...{
      "id": "1---clone-the-repository-to-your-local"
    }}>{`1 - Clone the repository to your local`}</h4>
    <pre><code parentName="pre" {...{}}>{`$ git git@github.com:mortoni/qvsr.git
`}</code></pre>
    <h4 {...{
      "id": "2---install-dependencies"
    }}>{`2 - Install dependencies`}</h4>
    <pre><code parentName="pre" {...{}}>{`$ cd qvsr
$ yarn
`}</code></pre>
    <h4 {...{
      "id": "3---install-functions-dependencies"
    }}>{`3 - Install functions dependencies`}</h4>
    <pre><code parentName="pre" {...{}}>{`$ cd function
$ npm i
`}</code></pre>
    <h2 {...{
      "id": "firebase"
    }}>{`Firebase`}</h2>
    <p>{`This is a step by step guide in how to create a project for this web application.`}</p>
    <p>{`1 - Go to the `}<a parentName="p" {...{
        "href": "https://console.firebase.google.com"
      }}>{`Firebase Console`}</a>{` and add a new project.`}<br parentName="p"></br>{`
`}{`2 - Add a Web app to your project (Firebase will add Firebase SDK to your project and give you the firebase configuration variables.). Make sure you follow every step.`}<br parentName="p"></br>{`
`}{`4 - Create a new Cloud Firestore database (Cloud Firestore menu)`}<br parentName="p"></br>{`
`}{`5 - Enable Email/Password sign in method to your project (click on Authentication menu)`}<br parentName="p"></br>{`
`}{`6 - Set up Firebase Hosting (Hosting menu)`}<br parentName="p"></br>{`
`}{`7 - Set up Firebase Firestore`}<br parentName="p"></br>{`
`}{`8 - Set up Functions (Functions menu), you will have to upgrade your Firebase plan in order to use Functions.`}</p>
    <h2 {...{
      "id": "configuration"
    }}>{`Configuration`}</h2>
    <div style={{
      textAlign: 'justify'
    }}>
  At the moment we deal with 2 `.env`, one for the root and another one for functions (it is necessary because, the
  deploy command is not able to get the .env variables from root). In each folder (root and functions) you will be able
  to find `.env.examples` file to guide you in creating those files. If you follow the Firebase setup above, you will be
  able to get the variables that you need for this.
    </div>
    <h2 {...{
      "id": "commands"
    }}>{`Commands`}</h2>
    <p>{`Please follow commands in package.json`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      